<template>
<div class="board-container">
    <forms-list-categories v-if="code == 'forms'" />
    <div class="board-list-attachment">
        <div class="row">
            <div v-for="board in boards" :key="board._id" class="col-6 col-sm-4 col-md-3">
                <div class="board-list-attachment__inner">
                    <div>
                        <div class="board-list-attachment__img-wrap">
                            <div class="board-list-attachment__img" :style="{ backgroundImage: `url('${board.thumb}')` }"></div>
                        </div>
                        <div class="board-list-attachment__con">
                            <div v-if="board?.category" class="mb-4">
                                <span class="font-size-14 grey-68--text">{{ categories[board?.category]?.text }}</span>
                            </div>
                            <div class="board-list-attachment__tit-wrap mb-10 mb-md-16">
                                <div class="board-list-attachment__tit ellip"><span>{{ board.subject }}</span></div>
                            </div>
                            <v-divider class="grey-da"></v-divider>
                            <div class="py-6">
                                <v-row no-gutters>
                                    <v-col>
                                        <v-btn @click="$emit('click', board)" small block outlined color="grey-da"><span class="primary--text">보기</span></v-btn>
                                    </v-col>
                                    <v-col class="ml-sm-n1">
                                        <v-btn :href="board?.files?.[0]?.url" download @click.stop small block outlined color="grey-da"><span class="primary--text">다운로드</span></v-btn>
                                    </v-col>
                                </v-row>
                            </div>
                            <v-divider class="primary"></v-divider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { BOARD_FORMS_CATEGORIES } from '@/assets/variables';
import FormsListCategories from './forms-list-categories.vue'
export default {
    components: { FormsListCategories },
    props: {
        summary: { type: Object, default () { return { totalCount: 0 }} },
        boards: { type: Array, default: null },
        code: { type: String, default: null },
    },
    computed:{
        categories() {
            if (this.code == "forms") return BOARD_FORMS_CATEGORIES;
            return {}
        },
    }
}
</script>

<style lang="scss" scoped>
/* Board Style - Attachment */
.board-list-attachment { width: 100%; }
.board-list-attachment .row{ margin:-10px -5px; }
.board-list-attachment .row [class*="col"]{ padding:10px 5px; }
.board-list-attachment__inner, .board-list-attachment >a{
    position: relative;
}
.board-list-attachment__img-wrap{
    position: relative;
    width: 100%;
    padding-top: 100%;
    margin: auto;
    border:1px solid #efefef;
    overflow: hidden;
    background-color: #efefef;
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    background-image:url(/images/common/noimg.png);
}
.board-list-attachment__img{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%) scale(1);
    transform-origin: center;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position:center;
    background-size: contain;
    -webkit-transition: transform ease-out .5s; -ms-transition: transform ease-out .5s; transition: transform ease-out .5s;
    animation: FadeIn .4s ease-in-out .5s forwards;
    opacity: 0;
}
.board-list-attachment__img::before{
    content:'';
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%) scale(1);
    transform-origin: center;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.2);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all ease-out .5s; -ms-transition: all ease-out .5s; transition: all ease-out .5s;
}
.board-list-attachment__con{
    padding: 10px 0 0;
    text-align: left;
}
.board-list-attachment__tit-wrap{
    position: relative;
}
.board-list-attachment__tit{
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
}
.board-list-attachment__more{
    display:none;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 0;
    -webkit-transform: translate(0,-50%); -ms-transform: translate(0,-50%); transform: translate(0,-50%);
    align-items: center;
    font-size: 1.4rem;
    color: var(--v-primary-base);
}
.board-list-attachment__txt{
    font-size: 1.4rem;
    color: #999;
    padding-top: 8px;
    margin-top: 8px;
    border-top: 1px solid #ccc;
}
.board-list-attachment__html, .board-list-attachment__html> *{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.board-list-attachment__html{
    display: block;
    height: 1.6em;
    line-height: 1.6;
}
.board-list-attachment__html >*{
    display: inline-block;
    max-width: 100%;
}
.board-list-attachment .board-empty-message{
    padding: 60px 20px;
    border-top: 2px solid #222;
    border-bottom: 1px solid #ddd;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .board-list-attachment__more{
        display:flex;
    }
    .board-list-attachment__txt{
        font-size: 1.6rem;
    }
    .board-list-attachment .board-empty-message{
        padding: 20px 10px;
    }
}
@media (min-width:1024px){
    .board-list-attachment .row{ margin:-20px -12px; }
    .board-list-attachment .row [class*="col"]{padding:20px 12px;}
    .board-list-attachment__con{
        padding:12px 0 0;
    }
    .board-list-attachment__img-wrap::before{
        width: 56px;
        height: 56px;
    }
    .board-list-attachment__inner >a:hover .board-list-attachment__img::before{
        opacity: 1;
        visibility: visible;
    }
    .board-list-attachment__inner >a:hover .board-list-attachment__img{
        opacity: 1;
        visibility: visible;
        transform: translate(-50%,-50%) scale(1.2);
    }
    .board-list-attachment__tit{
        font-size: 1.8rem;
    }
    .board-list-attachment__txt{
        font-size: 1.8rem;
        padding-top: 14px;
        margin-top: 14px;
    }
    .board-list-attachment__more{
        font-size: 1.6rem;
    }
    .board-list-attachment .board-empty-message{
        padding: 40px 20px;
    }
}
@media (min-width:1200px){
}
</style>